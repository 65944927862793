.conversation-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    max-width: 100%;
    margin: 0 auto;
    padding: 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: transparent;
    z-index: 1;
    position: relative;

}

.c-stv-convo-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 128px);
    max-width: 100%;
    margin-top: 128px;
    padding: 0px;
    overflow: hidden;
    background-color: transparent;
    z-index: 1;
    position: relative;

}


.message-group {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.messages-container {
    overflow-y: auto;
    flex: 1;
    z-index: 100;
    background-color: transparent;
    position: relative;
    height: 100%;
    padding-bottom: 10%;
}

.message-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 70%;
    margin: auto;
}

.avatar-section {
    min-width: 40px;
    text-align: right;
    position: relative;
}

.c-name-chip {
    position: absolute;
    left: 0;            /* Position it to the left of the avatar */
    top: 25%;           /* Position it in the middle of the avatar */
    transform: translateX(-120%); /* Move it entirely to the left of the reference point */
    white-space: nowrap; /* Prevent wrapping inside the chip */
    background-color: #1976D2;
    color:#1976D2;

}

.message-content {
    flex: 1;
    padding: 10px;
    border-radius: 8px;
}

.input-section {
    margin-right: auto;
    position: absolute;
    margin-left: auto;
    width: 60%;
    margin-bottom: 0%;
    justify-content: space-between;
    padding-right: 0px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    border-radius: 10px;
    bottom: 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.05);
    left: 20%;

}

.text-input-container {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 999;
    width: 100%; /* Take full width of the parent container */
    margin-bottom: 1%; /* Adjust as needed */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    bottom: 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.message-input {
    flex: 1;
    padding: 10px;
    padding-right: 65px; /* Space for the button */
    border: none;
    border-radius: 8px;
    outline: none; /* Remove default focus outline */
    resize: none;
    overflow-y: auto; /* Allow vertical scrolling */
    font-size: 16px;
    font-family: Arial, sans-serif; /* Use the font of your messages */
    z-index: 1; /* Ensure it stays on top */
    min-height: 40px;
    max-height: 100px;

}

.message-input::placeholder {
    font-family: Arial, sans-serif; /* Use the font of your messages */
    color: #a9a9a9; /* Typically, placeholders have a slightly faded color, but you can adjust this */
    font-size: "16px";
}


.convo-button {
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    right: 5px; /* adjust based on your design */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #007BFF; /* default color, change to your preference */
    transition: color 0.3s; /* smooth transition for hover effect */
    margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
}

.send-button {
    position: absolute;
    right: 5px; /* Adjust as needed */
    padding: 20px;
    height: 40px;
    width: 40px;

    background: none;
    border: none;
    cursor: pointer;
    color: #007BFF; /* default color, change to your preference */
    z-index: 2; /* Keep the button on top */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-gray-50 {
    background-color: #f1f1f1;
}

.bg-white {
    background-color: #FFFFFF;
}

button:hover {
    background: #f1f1f1;
}

button:focus {
    outline: none;
}


.message-input:focus {
    outline: none;
    box-shadow: none; /* This can remove a potential glow/shadow in some browsers */
}

.skeleton-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    animation: shimmer 1.5s infinite;
  }

.skeleton-message-content {
    width: 60%;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    animation: shimmer 1.5s infinite;
  }
.skeleton-avatar, .skeleton-message-content {
    background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0);
    background-size: 200% 100%;
}
.skeleton-avatar, .skeleton-message-content {
    animation: fadeIn 0.5s forwards, shimmer 1.5s infinite;
}


  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
