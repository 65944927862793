.t-conversation-container {
    width:600px;
    margin: 0 ;
    padding: 0px;
    overflow: hidden;
    background-color: transparent;
    z-index: 1;
    height: calc(100vh - 64px);
    display: flex;
    position: relative;
    flex-direction: row;
}

.t-message-group-ai {
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    padding: 0px;
    border-radius: 10px 10px 10px 10px; 
    margin-right: 40px;
    margin-left: 2px;
    margin-bottom: 15px;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
}
.t-message-group-user {
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    padding: 0px;
    border-radius: 10px 10px 10px 10px; 
    margin-left: 40px;
    margin-right: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
}
.t-messages-container {
    overflow-y: auto;
    flex: 1;
    z-index: 100;
    background-color: transparent;
    position: relative;
    padding-bottom: 10%;
    height: calc(100vh - 64px);
}

.t-message-wrapper {
    align-items: center;
    max-width: 100%;
    margin: 0%;
    position: relative; 

}

.t-transcript-box {
    /* other styles you need */
    overflow: hidden; /* Initially hidden */
    flex-grow: 0; /* Start with no size */
    transition: flex-grow 0.5s ease-in-out; /* This enables the smooth transition */
  }
  

.t-name-chip {
    position: absolute;
    transform: translateX(-20%) translateY(-50%); /* Move it entirely to the left of the reference point */
    white-space: nowrap; /* Prevent wrapping inside the chip */
}

.t-message-content {
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    line-height: 1.5;
    font-size: 17px;
    white-space: pre-line;
    }




.trans-button {
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    right: 5px; /* adjust based on your design */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #007BFF; /* default color, change to your preference */
    transition: color 0.3s; /* smooth transition for hover effect */
    margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
}



.t-bg-blue-text-white {
    background: linear-gradient(to bottom, #1976D2, #156CB0);
    color: #FFFFFF
}

.t-bg-white {
    background-color: #FFFFFF;
}


button:focus {
    outline: none;
}




.t-skeleton-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    animation: shimmer 1.5s infinite;
  }

.t-skeleton-message-content {
    width: 60%;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    animation: shimmer 1.5s infinite;
  }
.t-skeleton-avatar, .t-skeleton-message-content {
    background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0);
    background-size: 200% 100%;
}
.t-skeleton-avatar, .t-skeleton-message-content {
    animation: fadeIn 0.5s forwards, shimmer 1.5s infinite;
}


  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
