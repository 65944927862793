* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,500&display=swap');


body {
  font-family: 'Arial','Roboto', 'Roboto Condensed',  sans-serif;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  background-color: #fff;
  padding: 0px;
}

.topBarContainer {
  width: 100%;
  align-self: flex-start; 
  padding: 0px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  width: 100%; 
  flex-grow: 1; 
  margin-top: -64px;
  padding: 20px;
}

.course-header {
  width: 100%;
  flex-grow: 1; 
  margin-top: 32px;
  padding: 20px;
}



.course-top-nav{
  margin: 0 30px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  height: 64px;
}

.course-top-nav h2 {

  margin: 0;  

}

.course-menu-link {
  text-decoration: none;
  color: #0374B5;
  font-size: 16px;
}

.course-crumbs {
  text-decoration: none;
  color: #0374B5;
}

.course-menu-list {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 20px;
}

.course-menu-link:hover {
  text-decoration: underline;
}

.course-menu-list-item {
  margin-bottom: 10px;
  line-height: 1.5;
}

.search-results-container {
  padding: 20px;
}

.search-result-link {
  text-decoration: none;
  color: inherit;
}

.search-result {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.search-result:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.case-detail-container {
  padding: 20px;
}

/* Add more styling as needed */



.transcript-box {
  /* other styles you need */
  overflow: hidden; /* Initially hidden */
  transition: flex-grow 0.5s ease-in-out; /* This enables the smooth transition */
}


.delayed-transition {
  transition-delay: 20s !important; /* Adjust delay as needed */
}


h1, h2 {
  margin-bottom: 20px;
}

/*button {
  display: inline-block;
  margin: 10px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}*/

/*button:hover, a:hover {
  background-color: #0056b3;
}*/
.case-library {
  padding: 0px;
}

.case-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* You can adjust this for more or fewer columns */
  gap: 20px;
  margin-bottom: 20px;
}
.case-link {
  text-decoration: none;
  color: inherit;
}


.case {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;  /* Removes underline from link */
  background-color: #fff;
  color: inherit;         /* Ensures text color isn't the browser default for links */
}

.case:hover {
  background-color: #f5f5f5;  /* A subtle hover effect */
}


.case h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.case p {
  font-size: 14px;
  color: #777;
}

/* App.css */
.big-button {
  width: 200px;
  height: 200px;
  font-size: 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}


.big-button img {
  width: 50%;
  height: auto;
  margin-right: 10px;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.swing-animation {
  animation: swing 0.4s ease-in-out;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.buffer-wheel {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%; /* Circle */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  bottom: 150px;
  left: 50%;
}

.blank-line {
  height: 10px; /* or whatever height you desire for the blank line */
}

.highlighted {
  background-color: yellow;
}

.voice-button {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 400px;
  height: 50px;
  font-size: 16px;
  background-color: #1976D2;
  color: white;
  cursor: pointer;
  border: 2px solid #1565c0;
  border-top: 1px solid #64b5f6;
  border-radius: 3px;
  transition: all 0.1s;
}

.voice-button.voice-button:hover {
  background-color: #1565c0;
  border-top: 1px solid #64b5f6;
  transform: translateX(-50%) scale(1.02) translateY(-1px);
  border-left-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
}

.voice-button.listening {
  transform: translateX(-50%) scale(0.999) translateY(1px);
  border-left-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
}

/* Additional styles for .ellipsis or any other elements */


/* ellipsis-animation.css */

/*.ellipsis:after {
  overflow: hidden;
  position: absolute;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  -webkit-animation: ellipsis 1.5s infinite;
  animation: ellipsis 1.5s infinite;
  content: "";
  /* ascii code for the ellipsis character 
  width: 0px;
}

@keyframes ellipsis {
    0%   { content: ''; }
    33%  { content: '.'; }
    67%  { content: '..'; }
    100%  { content: '...'; }
    to  { width: 1.25em; }
}

@-webkit-keyframes ellipsis {
    0%   { content: ''; }
    33%  { content: '.'; }
    67%  { content: '..'; }
    100%  { content: '...'; }
    to { width: 1.25em; }
} */

/* Add this to your NewCaseDetail.css file */


.l-12col-container {
  justify-content: center;
  display: grid;
  grid-template-areas: "column1 column2 column3 column4 column5 column6 column7 column8 column9 column10 column11 column12";
  grid-template-columns: repeat(12,1fr);
  margin-right: auto;
  margin-left: auto;
}

.c-panel {
  padding-top: 48px;
  padding-bottom: 48px;
}

.c-panel .c-panel_content {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.c-card {
  background: white;
  border: 0px solid grey;
  box-sizing: border-box;
  border-radius: 6px;
  display: block;
  margin-bottom: 16px;
  padding: 16px;
}
.u-mb-hr {
  margin-bottom: .5rem;  
}

.u-bb-3px {
  border-bottom: 3px solid #1976D2;
}
.u-ul-1r {
  text-decoration-line: underline;
  text-decoration-style: solid;
  margin-bottom: 1rem;
}
.u-mt-1r {
  margin-top: 1rem;
}
.u-bs-2s {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.case-sections {
  margin-top: 48px;
}
.case-sections p {
  margin-bottom: 1rem;
  font-size: 17px;
  line-height: 1.5;
}
.p-case-subsection {
  margin-bottom: 32px;
}  

.c-arg-sets {
  position: sticky;
  height: 100%;
}

.c-set-sections {
  margin-top: 48px;
}
.l-col-xs-12 {
  grid-column-end: span 12;
}

.l-12col-container>* {
  padding-right: 16px;
  padding-left: 16px;
}
.my-start-button {
  background-color: transparent; /* Same as the default background color */
  color: transparent;
}
.my-start-button:hover {
  background-color: transparent; /* Same as the default background color */
}

@media (min-width: 992px) {
.l-col-md-3 {
  grid-column-end: span 3;
  height: 60vh;
  top: 100px;
  position: sticky;}
}


@media (min-width: 992px) {
  .l-col-md-9 {
      grid-column-end: span 9;
  }
}

@media (min-width: 1200px) {
  .l-12col-container {
    max-width: 1152px;
    grid-template-columns: repeat(12, minmax(64px, 1fr));
  }
}


